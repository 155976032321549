import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {IntlProvider} from 'react-intl';
import zh_HK from './locales/zh_HK.json';
import zh_CN from './locales/zh_CN.json';
import en_US from './locales/en_US.json';

const locale = navigator.language;
let localization;
if (locale === "en_US") {
  localization = en_US;
} else if (locale === "zh_HK") {
  localization = zh_HK;
} else if (locale === "zh_CN") {
  localization = zh_CN;
}


const theme = extendTheme({
  config: {
    initialColorMode: "light"
  }
});

// disable console.log
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>  

      <IntlProvider locale={locale} messages={en_US}>
        <App />
      </IntlProvider>  

    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals



// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { useMoralis } from "react-moralis";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
